/**  
 * Source: https://icon-sets.iconify.design/bx/error-circle/
 * Author: Atisa
 * License: CC BY 4.0
*/
function Error() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M13 17a.999.999 0 1 0-1.998 0a.999.999 0 0 0 1.997 0Zm-.26-7.853a.75.75 0 0 0-1.493.103l.004 4.502l.007.101a.75.75 0 0 0 1.493-.102l-.004-4.502l-.007-.102Zm1.23-5.488c-.857-1.547-3.082-1.547-3.938 0L2.286 17.661c-.83 1.5.255 3.339 1.97 3.339h15.49c1.714 0 2.799-1.84 1.969-3.34l-7.746-14Zm-2.626.727a.75.75 0 0 1 1.313 0l7.745 14a.75.75 0 0 1-.656 1.114H4.256a.75.75 0 0 1-.657-1.113l7.745-14.002Z"/></svg>
    )
}
export default Error;